import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { RollcallModel } from "@/models";
import { Common } from "../Common";

export class RollcallForm {
  time = '';
  city = '';
  school = '';
  class = '';
  teacher = '';
  note = '';
  student = [];
  rid = '';
  token = window.localStorage.getItem('adminToken') as string;
}

@Component<AdminRollcallRecordsController>({
  components: {
    SideNav, Lightbox
  }
})
export default class AdminRollcallRecordsController extends Vue {
  /*定義data 變數 */
  private doubleClick = false;
  private rollcallForm = new RollcallForm();
  private errorMsg = "";
  private openLB = false;
  private errNo = 0;
  private token = window.localStorage.getItem('adminToken') as string;

  public async created() {
    const item = {
      rid: this.$route.query.rid,
      token: this.token,
    }
    const editData = await RollcallModel.get(item);
    this.assignDataToForm(editData);
  }

  private assignDataToForm(data: unknown) {
    const key = data as {
      ERR_CODE: number,
      info: object,
    }
    if (key.ERR_CODE === ErrorCode.InvalidToken) {
      Common.logout();
      return;
    }

    const item = key.info as {
      rid: string;
      time: string;
      city: string;
      school: string;
      class: string;
      teacher: string;
      note: string;
      student: [];
    };
    this.rollcallForm.rid = item.rid;
    this.rollcallForm.time = item.time;
    this.rollcallForm.city = item.city;
    this.rollcallForm.school = item.school;
    this.rollcallForm.class = item.class;
    this.rollcallForm.teacher = item.teacher;
    this.rollcallForm.note = item.note;
    this.rollcallForm.student = item.student;
  }

  private async validateAndSubmit() {
    if (!this.doubleClick) {
      this.doubleClick = true;

      this.errNo = await RollcallModel.editRecord(this.rollcallForm);
      if (this.errNo === ErrorCode.InvalidToken) {
        Common.logout();
        return;
      }
      this.openLB = true;
      this.doubleClick = false;
      this.errorMsg = ErrorMessage[this.errNo];
    }
  }

  private closeLB() {
    this.openLB = false;
    if (this.errNo === ErrorCode.Success) {
      this.$router.push("/admin/rollcall");
    }
  }

}
